import { animate, style, transition, trigger } from '@angular/animations';

export const regularFontSizeAnimation = trigger('regularFontSizeAnimation', [
  transition(':enter', [style({ fontSize: 0 }), animate('0.5s ease-out', style({ fontSize: 16 }))]),
]);

export const smallFontSizeAnimation = trigger('smallFontSizeAnimation', [
  transition(':enter', [style({ fontSize: 0 }), animate('0.4s ease-out', style({ fontSize: 12 }))]),
]);


export const notificationAnimation = trigger('notificationAnimation', [
  transition(':enter', [style({ top: -200 }), animate('0.5s ease-out', style({ top: 10 }))]),
  transition(':leave', [style({ top: 10 }), animate('0.5s ease-in', style({ top: -200 }))]),
]);

export const menuAnimation = [
  trigger('overlayAnimation', [
    transition(':enter', [
      style({ opacity: 0, transform: 'scaleY(0.8)' }), animate('{{showTransitionParams}}'),
    ]),
    transition(':leave', [
      animate('{{hideTransitionParams}}', style({ opacity: 0 })),
    ]),
  ]),
];
